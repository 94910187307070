import React, { useEffect } from "react"

import { COLORS, BORDER_RADIUS } from "../styles/constants"

const Chart = ({ children }) => {
  useEffect(() => {
    const anchorChart = document.createElement("a")
    anchorChart.setAttribute("class", "metric_soup_chart")
    anchorChart.setAttribute("type", "openTimePerTaskChart")
    anchorChart.setAttribute("userid", "62152dd12e3351d59fc70238")
    anchorChart.setAttribute("projectid", "62152e0e2e3351d59fc7023f")
    anchorChart.setAttribute("charttype", "pie")
    document.getElementsByClassName("chart")[0].appendChild(anchorChart)

    const scriptChart = document.createElement("script")
    scriptChart.setAttribute(
      "src",
      "https://epic-payne-335385.netlify.app/index.js"
    )
    document.getElementsByClassName("chart")[0].appendChild(scriptChart)
  }, [])
  return (
    <div className="tooltip">
      <link
        href="https://epic-payne-335385.netlify.app/index.css"
        rel="stylesheet"
      />
      <script src="https://epic-payne-335385.netlify.app/index.js"></script>
      <div
        className="chart"
        style={{
          width: "fit-content",
          height: 500,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: BORDER_RADIUS,
          borderColor: COLORS.mediumGray,
        }}
      ></div>
      <span className="tooltiptext"></span>
    </div>
  )
}

export default Chart
