import React from "react"
import SectionHeader from "./SectionHeader"
//import Button from "./Button"

const CallToAction = props => {
  return (
    <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
      <SectionHeader
        title="Still not sure?"
        description="Play around with the public demo account or sign up if you want to start using it for your own projects."
      />

      <a href="https://app.metricsoup.app/">
        <button className="button">Try it out!</button>
      </a>
    </div>
  )
}

export default CallToAction
