import React from "react"
//import { Link } from "gatsby"
//import Button from "./Button"

const ValueProp = props => {
  return (
    <section className="value-prop">
      <br />
      <div className="value-prop-content">
        <div className="main-message">
          <h1> Track your time and ROI for your projects!</h1>
          <br />
          <a href="https://app.metricsoup.app/">
            <button className="button">Try it out!</button>
          </a>
        </div>
        <div className="main-photo"></div>
      </div>
    </section>
  )
}

export default ValueProp
