import React from "react"
import Chart from "./Chart"
import { BORDER_RADIUS, COLORS } from "../styles/constants"
import TimePerTaskDetails from "../assets/TimePerTaskDetails.png"
import ProfitLossChart from "../assets/ProfitLossChart.png"

const ProductBenefits = () => {
  return (
    <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        }}
      >
        <div>
          <h3>Track your time</h3>
          <p style={{ color: COLORS.gray }}>
            Know how much time you have spent and doing what
          </p>
        </div>
        <div title="Image showing how the time has been spent. I might have learned a few things.">
          <img
            src={TimePerTaskDetails}
            alt="a blank card floating over colorful graphics"
            style={{
              maxWidth: "30em",
              objectFit: "scale-down",
              borderRadius: BORDER_RADIUS,
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 360px))",
        }}
      >
        <div>
          <h3>Track your income/expenses</h3>
          <p style={{ color: COLORS.gray }}>
            Know how much you have earned/spent due to the project
          </p>
        </div>
        <div title="This is an image from a sample project. Embedding the chart for this one would look quite sad and we don't want that.">
          <img
            src={ProfitLossChart}
            alt="a blank card floating over colorful graphics"
            style={{
              maxWidth: "30em",
              objectFit: "scale-down",
              borderRadius: BORDER_RADIUS,
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        }}
      >
        <div>
          <h3>View charts and breakdowns of your data</h3>
          <p style={{ color: COLORS.gray }}>
            Get an overview and spot trends.
            <br />
          </p>
        </div>
        <div title="Like me having to spend more time doing non development tasks as seen with this pie chart.">
          <Chart />
        </div>
      </div>
    </div>
  )
}

export default ProductBenefits
